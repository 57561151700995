import core_theme from 'CORE__UI/globals/core__theme';

const theme = {
  ...core_theme,
  fontSource:
    'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,700;1,400&display=swap',
  fontFamily: `'source sans pro', 'sans-serif'`,
};

export default theme;
