import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  LC,
  LMW,
  TN,
  LEC as LEC_LOCAL,
  IMG as IMG_LOCAL,
} from 'UI/apps/CategoryListApp/ListContent';
import { IPC, S } from 'UI/navigation/CategoryListNodeStyles';
import { useSelector } from 'react-redux';
import Constants from '../../../../../../project';
import { GLOBAL_CONSTANTS, GLOBAL_PATH } from 'Services/global/core__constants';
import {
  IMG as IMG_CORE,
  LEC as LEC_CORE,
} from 'CORE__UI/apps/CategoryListApp/core__ListContent';
import I from 'CORE__UI/globals/Icon/core__icons';
import { Translations } from '../../../core__category-list-app';
import { SiteURL } from 'Services/core__site-url';
import { getComponent } from 'Services/core__imports';
import project from '../../../../../../project';
import Icon from 'UI/globals/Icons';

const LEC = getComponent(LEC_LOCAL, LEC_CORE);
const IMG = getComponent(IMG_LOCAL, IMG_CORE);
const LeftDrawerMenuListReact = ({
  activeMenu,
  liveEventCounter,
  isTranslated,
}) => {
  const [menuList, setMenuList] = useState([]);
  const [customMenuList, setCustomMenuList] = useState();
  const { leftNavMenuItemsList = {} } = useSelector(state => ({
    leftNavMenuItemsList: state?.data?.leftNavMenuItemsList,
  }));
  useEffect(() => {
    if (leftNavMenuItemsList) {
      setMenuList(leftNavMenuItemsList[`${activeMenu}`]);
      if (leftNavMenuItemsList.custompopular) {
        setCustomMenuList(leftNavMenuItemsList.custompopular?.[0]?.listItems);
      }
    }
  }, [activeMenu, leftNavMenuItemsList]);

  const imageUrl = Constants.imagesUrl;
  const getCustomMenuList = () => {
    if (customMenuList) {
      return customMenuList.map(
        ({ listName, listRoute, linkIcon, linkText }) => {
          const href = `${SiteURL.path}` + (listRoute || '');
          return (
            <LMW key={listName}>
              {' '}
              <LC>
                <IPC href={href}>
                  {linkIcon && (
                    <Icon iconName={linkIcon} size={18} marginR={12} />
                  )}
                  {listName}
                  {linkText && <LEC>{linkText}</LEC>}
                </IPC>
              </LC>
            </LMW>
          );
        }
      );
    }
  };
  return (
    <div>
      {menuList?.length > 0 &&
        menuList.map(({ title, listItems }) => (
          <React.Fragment key={title}>
            {title && (
              <TN> {isTranslated ? Translations.get(title) : title}</TN>
            )}
            {getCustomMenuList()}
            {listItems.length > 0 &&
              listItems.map(({ listName, listRoute, linkIcon }) => {
                const href = `${SiteURL.path}` + (listRoute || '');
                return (
                  <LMW key={listName}>
                    <LC>
                      {linkIcon && linkIcon.includes('bespoke') && (
                        <S active={true}>
                          <I
                            category={linkIcon.split('-')[1]}
                            bespokeSvgSportsIcons={
                              project.bespokeSvgSportsIcons
                            }
                            iconName={linkIcon}
                            marginLeft={16}
                          />
                        </S>
                      )}
                      {/* Colour icons */}
                      {linkIcon &&
                        !linkIcon.startsWith('icon-') &&
                        !linkIcon.includes('bespoke') && (
                          <IMG
                            src={imageUrl + linkIcon + '-80x80.webp'}
                            alt="menu-icons"
                          ></IMG>
                        )}
                      {listName && (
                        <IPC
                          bespokeSvgSportsIcons={linkIcon.includes('bespoke')}
                          href={href}
                        >
                          {/* Flat icons */}
                          {linkIcon && linkIcon.startsWith('icon-') && (
                            <S active={true}>
                              <I iconName={linkIcon} />
                            </S>
                          )}
                          {isTranslated ? Translations.get(listName) : listName}
                          {listRoute === GLOBAL_CONSTANTS.IN_PLAY_ROUTE && (
                            <LEC href={GLOBAL_PATH.SPORTS_BOOK}>
                              {liveEventCounter}
                            </LEC>
                          )}
                        </IPC>
                      )}
                    </LC>
                  </LMW>
                );
              })}
          </React.Fragment>
        ))}
      <div className="divider"></div>
    </div>
  );
};

LeftDrawerMenuListReact.propTypes = {
  activeMenu: PropTypes.string,
  liveEventCounter: PropTypes.number,
  isTranslated: PropTypes.bool,
};
export default LeftDrawerMenuListReact;
