import { CM, CMC } from 'CORE__UI/apps/CategoryListApp/core__MoreCategory';
import styled, { css } from 'styled-components';

const CM_QN = styled(CM)`
  ${({ showIcon }) =>
    !showIcon &&
    css`
      padding: 0 16px;
    `}
  i {
    margin-right: 12px;
  }
`;

export { CM_QN as CM, CMC };
