import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';

import { connect } from 'react-redux';
import createStore, {
  excludeCategory,
  getUrl,
  setAccessToken,
  storeFavList,
} from './core__category-list-store';
import CategoryListAppReact from './component/core__categoryListReact';
import ScDecoder from 'Services/json/core__decoder';
import {
  fetchData,
  subCategory,
  starredList,
  navigationList,
} from './core__category-list-store';
import { fetchData as fetchTopLeagues } from '../TopLeaguesApp/core__top-leagues-store';
import { TranslationsProvider } from 'Services/translations/core__translations';
import { translationStrings } from './core__category-list-translations';
export const Translations = new TranslationsProvider('CategoryListApp');

class CategoryListApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) {
      Translations.get = properties.Translations.get.bind(
        properties.Translations
      );
      Translations.getAll = properties.Translations.getAll.bind(
        properties.Translations
      );
      return;
    }

    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }

  start(data, reduxSelector) {
    const ReactInstance = this.getReactApp(data);
    Translations.setAll(data);
    const jsx = (
      <ReduxProvider store={createStore(data || {})}>
        <ReactInstance />
      </ReduxProvider>
    );

    ReactDOM[data ? 'hydrate' : 'render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }

  getReactApp(data) {
    this.url = getUrl(this.appConfig);
    const mapStateToProps = state => {
      // featuredList & sportName are used in fetching topLeague info
      return {
        url: this.url,
        appConfig: this.appConfig,
        category: data,
        categoryList: state.data.category || {},
        accessToken: state.data.accessToken,
        featuredList: state.topLeagues.featuredList,
        activeMenu: this.appConfig.requestURL.split('/')[1],
        sportName: this.appConfig.requestURL.split('/')[2],
      };
    };

    const mapDispatchToProps = {
      url: this.url,
      fetchData: fetchData,
      fetchTopLeagues: fetchTopLeagues,
      subCategory: subCategory,
      starredList: starredList,
      setAccessToken: setAccessToken,
      storeFavList: storeFavList,
      excludeCategory: excludeCategory,
      navigationList: navigationList,
    };

    CategoryListAppReact.serverFetch = {
      url: this.url,
      fetchTopLeagues,
      fetchData,
      createStore,
      strToTranslate: this.appConfig.istranslated
        ? __getTranslationsStrings(this.appConfig, translationStrings)
        : translationStrings,
    };

    return connect(mapStateToProps, mapDispatchToProps)(CategoryListAppReact);
  }
}
const __getTranslationsStrings = (appConfig, translationStrings) => {
  const {
    sportsbooklist,
    menutitleslist,
    casinolist,
    custompopularlist,
  } = appConfig;
  sportsbooklist?.split(',').forEach(item => {
    translationStrings.push(item);
  });
  menutitleslist?.split(',').forEach(item => {
    translationStrings.push(item);
  });
  casinolist?.split(',').forEach(item => {
    translationStrings.push(item);
  });
  custompopularlist?.split(',').forEach(item => {
    translationStrings.push(item);
  });
  return translationStrings;
};

export default CategoryListApp;
