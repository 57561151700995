import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import getComposeEnhancers from 'Services/redux/core__redux-dev-tools';
import { getRequest } from 'Services/http/core__axios';
import { createCookie } from 'Services/cookie/core__cookies';
import { dataReducer as topLeaguesReducer } from '../TopLeaguesApp/core__top-leagues-store';
import { bettingPools } from './core__category-utils';

export const dataReducer = (state = [], action) => {
  switch (action.type) {
    //Storing category information in redux store
    case 'CATEGORIES_DATA': {
      return {
        ...state,
        category: { ...action.response },
      };
    }

    //Storing sub category information in redux store
    case 'SUB_CATEGORIES': {
      return {
        ...state,
        subCategory: action.response,
      };
    }
    case 'ACCESS_TOKEN': {
      return {
        ...state,
        accessToken: action.token,
      };
    }
    case 'SIDE_MENU_LIST_ITEMS': {
      return {
        ...state,
        leftNavMenuItemsList: action.payload,
      };
    }
    default:
      return state;
  }
};

//Combine reducers into a single reducer
export const reducer = combineReducers({
  data: dataReducer,
  topLeagues: topLeaguesReducer,
});

//Function to get the url for category list
export function getUrl() {
  const base = `/fsb-api-rest/bet/category`;
  const url = `${base}.fsb`;
  return url;
}

//Function to get the url for category list according to category ref
export function getCategoryUrl(ref) {
  const base = `/fsb-api-rest/bet/category/${ref}`;
  const url = `${base}.fsb`;
  return url;
}
//Function to return sorted array
export const orderedList = (listOne, listTwo) => {
  if (listOne < listTwo) {
    return -1;
  }
  if (listOne > listTwo) {
    return 1;
  }
};
//function to order data according to the catref
export const orderCategory = (data, catref, favourite) => {
  const favArray = [];
  let nonFavArray = [];
  //seperating favourite and non favourite categories
  for (const list of data.category) {
    if (list.favourite && favourite) {
      favArray.push(list);
    } else {
      nonFavArray.push(list);
    }
  }
  const catRef = catref;
  const sortCatRef = catRef.split(',');
  const catRefAtTop = [];
  //shifting the category ref to the top of the array according to the cms
  for (const categoryRef of sortCatRef) {
    for (const list of data.category) {
      if (categoryRef.trim() === list.ref) {
        catRefAtTop.push(list);
      }
    }
  }
  nonFavArray = [...catRefAtTop, ...nonFavArray];
  //Eleminating the duplicate category list
  const categoryList = Array.from(new Set(nonFavArray));
  const categoryArray = [...favArray, ...categoryList];
  //Eleminating the duplicate category list
  data.category = Array.from(new Set(categoryArray));
  return data;
};

// Function to sort category list according to the cms configuration
export const sortingData = (data, ordering, favourite, catref) => {
  if (data.category && ordering) {
    data.category.sort(function (a, b) {
      if (
        ordering.toUpperCase() === 'ASC' ||
        ordering.toUpperCase() === 'DEFAULT'
      ) {
        return orderedList(a.name, b.name);
      } else {
        if (ordering.toUpperCase() === 'DSC') {
          return orderedList(b.name, a.name);
        } else {
          return orderedList(a.name, b.name);
        }
      }
    });
    if (favourite) {
      data.category.sort(function (a, b) {
        return b.favourite - a.favourite;
      });
    }
  }
  if (catref) {
    data = orderCategory(data, catref, favourite);
  }
  return data;
};

// Function to sort sub category list aplhabetically
export const sortingSubCategoryData = (data, ordering) => {
  const subCat = {};
  let sortedSubList;
  if (data?.category) {
    data.category[0].subcat.map(x => {
      if (!x.countryName) {
        // When country name is not present it is for the Rest of the world
        x.countryName = 'Rest of the world';
      }
      return x;
    });
    for (const subCategory of data.category[0].subcat) {
      const find = Object.keys(subCat).find(x => x === subCategory.countryName);
      if (!find) {
        subCat[subCategory.countryName] = [subCategory];
      } else {
        subCat[subCategory.countryName].push(subCategory);
      }
    }
    // sorting the events according to the cms configuration
    if (Object.keys(subCat).length > 0) {
      Object.keys(subCat).map(list => {
        subCat[list].sort((a, b) => {
          if (
            ordering &&
            (ordering.toUpperCase() === 'ASC' ||
              ordering.toUpperCase() === 'DEFAULT')
          ) {
            return orderedList(a.name, b.name);
          } else {
            return orderedList(b.name, a.name);
          }
        });
      });
      // sorting the country according to the cms configuration
      sortedSubList = Object.keys(subCat)
        .sort((a, b) => {
          if (
            ordering &&
            (ordering.toUpperCase() === 'ASC' ||
              ordering.toUpperCase() === 'DEFAULT')
          ) {
            return orderedList(a, b);
          } else {
            return orderedList(b, a);
          }
        })
        .reduce((result, key) => {
          result[key] = subCat[key];
          return result;
        }, {});
    }
  }

  return sortedSubList;
};

//this is a thunk middleware function, for initial api call and dispatch to store
export const fetchData = (
  url,
  nodeRequester,
  appConfig,
  favList,
  exclude
) => dispatch => {
  const requester = nodeRequester || getRequest;
  return requester(url, null, appConfig).then(res => {
    if (res) {
      if (exclude) {
        const arr = exclude.split(',');
        res.category = res?.category?.filter(val => {
          return arr.indexOf(val.ref) === -1;
        });
      }
      if (appConfig?.addpools) {
        res.category.push(bettingPools);
      }
      const setFavourite = setFavouriteList(res, favList);
      const response = sortingData(
        setFavourite,
        appConfig?.categoryorder,
        appConfig?.favourite,
        appConfig?.catref
      );
      dispatch({
        type: 'CATEGORIES_DATA',
        response,
      });
    }
  });
};

export const setFavouriteList = (list, favList) => {
  list?.category?.map(category => {
    if (favList && favList.length > 0 && favList.find(x => x === category.id)) {
      category['favourite'] = true;
    } else {
      category['favourite'] = false;
    }
    return category;
  });
  return list;
};

export const subCategory = (list, appConfig) => dispatch => {
  dispatch({ type: 'SUB_CATEGORIES' });
  if (list) {
    const url = getCategoryUrl(list);
    const requester = getRequest;
    return requester(url).then(res => {
      const response = sortingSubCategoryData(res, appConfig?.subcategoryorder);
      dispatch({ type: 'SUB_CATEGORIES', response });
    });
  }
};

// set access token to store
export const setAccessToken = token => dispatch => {
  dispatch({ type: 'ACCESS_TOKEN', token });
};

//storing favorite list
export const storeFavList = (categoryList, list, appConfig) => dispatch => {
  const setFavourite = setFavouriteList(categoryList, list);
  const response = sortingData(
    setFavourite,
    appConfig?.categoryorder,
    appConfig?.favourite,
    appConfig?.catref
  );
  dispatch({
    type: 'CATEGORIES_DATA',
    response,
  });
};

//excluding category from list excludeCategory

export const excludeCategory = (categoryList, exclude) => dispatch => {
  const arr = exclude.split(',');
  if (categoryList?.category) {
    categoryList.category = categoryList?.category?.filter(val => {
      return arr.indexOf(val.ref) === -1;
    });
  }

  dispatch({
    type: 'CATEGORIES_DATA',
    response: categoryList,
  });
};

//Function to set favourite category in the cookie
export const starredList = (list, category, appConfig, favList) => dispatch => {
  const findList = favList && favList.findIndex(fav => fav === list.id);
  if (findList !== -1) {
    favList.splice(findList, 1);
  } else {
    favList = [...favList, list.id];
  }
  createCookie('favouriteList', favList);

  const setFavourite = setFavouriteList(category, favList);
  const response = sortingData(
    setFavourite,
    appConfig?.categoryorder,
    appConfig?.favourite,
    appConfig?.catref
  );
  dispatch({
    type: 'CATEGORIES_DATA',
    response,
  });
};

export const navigationList = data => dispatch => {
  const leftNavMenuItemsList = {};
  const sideMenuRouteList = data?.menurouteslist?.split(',') || [];
  const sideMenuTitleList = data?.menutitleslist?.split(',') || [];
  if (sideMenuRouteList.length > 0) {
    sideMenuRouteList.map((menuRoute, index) => {
      leftNavMenuItemsList[menuRoute] = [
        {
          title: sideMenuTitleList[index] || '',
          listItems: [],
        },
      ];
      const menuTitleLists = data?.[`${menuRoute}list`]?.split(',') || [];
      const menuTitleListsIcons = data?.[`${menuRoute}icons`]?.split(',') || [];
      const menuTitleListsLinks = data?.[`${menuRoute}links`]?.split(',') || [];
      const menuTitleListText =
        data?.[`${menuRoute}featuretext`]?.split(',') || [];

      const menuTilteListLength =
        menuTitleLists.length || menuTitleListsIcons.length || 0;
      if (menuTilteListLength > 0) {
        for (let i = 0; i < menuTilteListLength; i++) {
          const listItem = {
            listName: menuTitleLists[i] || '',
            listRoute: menuTitleListsLinks[i] || '',
            linkIcon: menuTitleListsIcons[i] || '',
            linkText: menuTitleListText[i] || '',
          };
          leftNavMenuItemsList[menuRoute][0].listItems.push(listItem);
        }
      }
    });
  }

  dispatch({ type: 'SIDE_MENU_LIST_ITEMS', payload: leftNavMenuItemsList });
};

const composeEnhancers = getComposeEnhancers({ name: 'category-list-store' });

export default initialState =>
  createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
