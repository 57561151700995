import {
  RL,
  FRT,
  FRMD,
  FRD,
  FRSM,
  FRS,
  FRA,
} from '../../../core__ui-library/apps/TopLeaguesApp/core__topLeaguesPage';
import styled from 'styled-components';
import { GREYS, BK_COLORS } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';
const RL_Q = styled(RL)`
  box-shadow: none;
  @media (max-width: ${BREAKPOINT_S}) {
    border-radius: 0;
  }
`;
const FRT_Q = styled(FRT)`
  color: ${GREYS.white};
  background-color: ${BK_COLORS.blue};
  border-color: ${BK_COLORS.aliceBlue10};
  border-radius: 4px 4px 0 0;
  @media (max-width: ${BREAKPOINT_S}) {
    border-radius: 0;
  }
`;
const FRMD_Q = styled(FRMD)`
  border-color: ${BK_COLORS.aliceBlue10};
  background-color: ${BK_COLORS.EVENTS.bodySection};
  margin-bottom: 0;
`;
const FRSM_Q = styled(FRSM)`
  border-color: ${BK_COLORS.aliceBlue10};
`;
const FRA_Q = styled(FRA)`
  &:after {
    color: ${GREYS.black};
  }
`;
const FRD_Q = styled(FRD)`
  color: ${GREYS.black};
`;
export {
  RL_Q as RL,
  FRT_Q as FRT,
  FRMD_Q as FRMD,
  FRD_Q as FRD,
  FRSM_Q as FRSM,
  FRS,
  FRA_Q as FRA,
};
