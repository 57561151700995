import styled from 'styled-components';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';
import theme from 'UI/globals/theme';
import { BRAND, EX_COLORS, FONT, SHADOW } from 'UI/globals/colours';

export const LC = styled.div`
  width: 224px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-family: ${theme.fontFamily};
  color: ${props => (props.active ? `${FONT.primary}` : `${FONT.dark}`)};
  &:hover {
    background-color: ${FONT.light};
    color: ${BRAND.primary};
  }
  background-color: ${props => (props.active ? `${FONT.light}` : 'none')};
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  box-shadow: ${props => (props.active ? `${SHADOW}` : 'none')};
  border-radius: 4px;
  @media (max-width: ${BREAKPOINT_S}) {
    width: 100%;
    max-width: 100%;
  }
`;

export const LMW = styled.li`
  transition: height 1.2s ease-out;
  cursor: default;
  @media (max-width: ${BREAKPOINT_S}) {
    width: 100%;
    max-width: 100%;
  }
`;
export const TN = styled.li`
  height: 36px;
  color: ${({ theme }) => (theme.dark ? BRAND.primary : FONT.secondary)};
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 16px;
  cursor: default;
  @media (max-width: ${BREAKPOINT_S}) {
    width: 100%;
    max-width: 100%;
  }
`;
export const CL = styled.ul`
  cursor: pointer;
  @media (max-width: ${BREAKPOINT_S}) {
    width: 100%;
    max-width: 100%;
  }
`;

export const IMG = styled.img`
  width: 20px;
  margin-left: 8px;
`;

export const LEC = styled.span`
  align-items: center;
  background: ${EX_COLORS?.brandYellow || BRAND.primary};
  border-radius: 4px;
  font-size: 12px;
  font-weight: 700;
  height: auto;
  right: 0;
  text-align: center;
  width: auto;
  margin-left: auto;
  padding: 4px;
`;
