import {
  LC,
  LMW,
  TN,
  CL,
  LEC,
  IMG,
} from 'CORE__UI/apps/CategoryListApp/core__ListContent';
import styled from 'styled-components';
import { BK_COLORS, GREYS, FONT } from 'UI/globals/colours';

const TN_QN = styled(TN)`
  padding-left: 8px;
  font-weight: 800;
  color: ${GREYS.black};
`;

const LEC_EX = styled(LEC)`
  color: ${FONT.white.label};
  background-color: ${BK_COLORS.red};
  text-transform: uppercase;
`;

const IMG_EX = styled(IMG)`
  margin: 0 12px 0 0;
  width: 16px;
`;

export { LC, LMW, TN_QN as TN, CL, LEC_EX as LEC, IMG_EX as IMG };
