import {
  ND,
  W,
  P,
  ST,
} from 'CORE__UI/globals/NoEventWithBtn/core__noEventStylesWithBtn';
import styled from 'styled-components';
import { BRAND } from '../globals/colours';
const LC_RB = styled.div`
  cursor: pointer;
`;
const A_RB = styled.a`
  color: ${BRAND.primary};
`;
export { ND, W, P, A_RB as A, LC_RB as LC, ST };
