import {
  CLN,
  IS,
  S,
  CNI,
  SC,
  SS,
  CA,
  IPC,
} from 'CORE__UI/navigation/CategoryListNode/core__categoryListNodeStyles';
import styled, { css } from 'styled-components';
import { FONT, GREYS } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

const CLN_QN = styled(CLN)`
  &:hover {
    background-color: ${FONT.light};
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  ${({ active }) =>
    active &&
    css`
      background-color: ${FONT.light};
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    `}
  ${({ active, type }) =>
    active &&
    type !== 'subcat' &&
    css`
      background-color: ${FONT.light};
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    `}
  // Showing active state when subcat is selected
  ${({ active, type }) =>
    active &&
    type === 'subcat' &&
    css`
      background-color: ${FONT.light};
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    `}
  ${({ expanded, type }) =>
    expanded &&
    type === 'category' &&
    css`
      background-color: ${FONT.light};
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    `}
  ${({ active, type }) =>
    active &&
    type === 'category' &&
    css`
      background-color: ${FONT.light};
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    `}
`;

const SS_QN = styled(SS)`
  &[data-favourite='false'] {
    color: ${'rgba(0, 0, 0, 0.2)' || 'currentColor'};
  }
  @media (max-width: ${BREAKPOINT_S}) {
    position: absolute;
  }
`;

const S_QN = styled(S)`
  color: ${GREYS.black};
`;

const IPC_EX = styled(IPC)`
  &:hover {
    background-color: ${FONT.light};
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
`;

export {
  CLN_QN as CLN,
  IS,
  S_QN as S,
  CNI,
  SC,
  SS_QN as SS,
  CA,
  IPC_EX as IPC,
};
