import styled, { css } from 'styled-components';
import { I } from 'CORE__UI/globals/Icon/core__iconsStyles';
import { SUPPLEMENTARY } from './colours';

const I_EX = styled(I)`
  color: inherit;
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
  ${({ betBooster }) =>
    betBooster &&
    css`
      color: ${SUPPLEMENTARY.yellow};
    `}
`;

export { I_EX as I };
